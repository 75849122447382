const Url = require("url-parse");
let cfOrigin = "";

if (process.env.GATSBY_CF_DOMAIN) {
  cfOrigin = process.env.GATSBY_CF_DOMAIN;
}

export { cfOrigin };

export function getCfUrl(url: string): string {
  const parser = new Url(url);
  return cfOrigin
    ? `${cfOrigin}${parser.pathname}`
    : `${parser.origin}${parser.pathname}`;
}
